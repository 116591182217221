import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views-xz/main";

//views - transfers
import TransfersClaim from "../views-xx/transfers-claim";
import TransfersMint from "../views-xx/transfers-mint";


//views - wallets
import WalletsCreateEthers from "../views-xx/wallets-create-evm";
import WalletsCreateRand from "../views-xx/wallets-create-rand";


const routes = [

  { route:'/', content: <Main />, auth:false },

  // tokens
  { route:'/ax/:id', content: <TransfersClaim />, auth:false },
  { route:'/am/:id', content: <TransfersMint />, auth:false },

  // wallet
  { route:'/account/evm', content: <WalletsCreateEthers />, auth:false },
  { route:'/account/rand', content: <WalletsCreateRand />, auth:false },

]


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}