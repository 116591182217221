// init
const algosdk = require('algosdk')

// Function Calls


export const CreateAlgorandWallet= async (item) => {

  try {
    const account = algosdk.generateAccount()
    const mnemonic = algosdk.secretKeyToMnemonic(account.sk)
    console.log (account, mnemonic)
    
    return {data: {address: account.addr, mnemonic: mnemonic, secret: '******'}}

  } catch (error) {
    console.log (error);
    return {data: false}
  }
  
}