// wallets
import { useState } from "react";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";

import { CreateEthereumWallet } from "../../services/srvc-wallet-ethers";


// lists --------------->


// lists --------------->


// code --------------->

export default function WalletsCreateModule() {

  const [loading, setLoading] = useState(false);  
  
  // const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    address: '******',
    mnemonic: '******',
    secret: '******'
  })


  const handleWallet = async() => {
    setLoading(true);

    const wallet = await CreateEthereumWallet();
    // console.log (wallet);
    setData({
      address: wallet.data.address,
      mnemonic: wallet.data.mnemonic.toString(),
      secret: wallet.data.secret
    })
    setDone(true);
    setLoading(false);
  }

  function handleSave() {

    const content = 'WALLET ADDRESS\n' + data.address + '\n\nRECOVERY PHASE (MNEMONIC)\n'  + data.mnemonic

    const blob = new Blob([content], {type: 'text/plain'});

    if(window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `tokenize-wallet-${Date.now().toString()}`);
    }
    else{
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = `tokenize-wallet-${Date.now().toString()}`;
        document.body.appendChild(elem);
        elem.click();        
        document.body.removeChild(elem);
    }
}

  if (loading) return <WebbLoader/>

  return (
  <>
    
    {/* media */}
    <div className="p-3 back-color-wite rounded-wd">
      <h3 className="text-color-main m-0">Create Wallet</h3>
      <WebbDividerSmall />
      <p className="m-0 mb-2">
        You can Create a New Ethereum Wallet here. It will work on all EVM Chains.
        Once created, you can import this into browser wallets like Metamask.
      </p>
      <p className="m-0">
        Alternative Option: You can visit <a href="https://metamask.io">metamask.io</a> to setup and create a wallet.
      </p>

      <WebbDividerMedium />
      <button
        onClick={() => handleWallet()}
        className={`btn btn-primary height-md hirich rounded-wd border-none`}
        style={{width:'100%'}}>
        Create Wallet
      </button>
    </div>
    
    <WebbDividerMedium />


    <WebbDividerMedium />
    <div className={`p-3 back-color-wite rounded-wd ${done ? '': 'd-none'}`}>
      <p className="text-bold m-0" style={{textTransform:'uppercase'}}>Wallet Address</p>
      <p className="text-color-main m-0">{data.address}</p>

      <p className="text-mini text-color-tone m-0">You can share this address with other to claim and collect digital assets</p>

      <WebbDividerMedium />
      <p className="text-bold m-0" style={{textTransform:'uppercase'}}>Recovery Phrase</p>
      <p className="text-color-main m-0">{data.mnemonic}</p>


      <p className="text-mini text-color-tone m-0">Do not share this with anyone, and write it down somewhere and keep it safe. This can be used to recover your account.</p>
      {/* <WebbDividerMedium />
      <p className="text-bold m-0" style={{textTransform:'uppercase'}}>Private Key</p>
      <p className="text-color-main m-0 text-sm">{data.secret}</p>

      <p className="text-mini text-color-tone m-0">Do not share this with anyone, and Write it down somewhere and keep it safe. This can be used to recover your account.</p>
     */}
      <WebbDividerMedium />
      <WebbDividerMedium />
      
      
      <span 
        className={`back-color-dark text-color-wite p-2 px-3 rounded-md hirich ${done ? '': 'd-none'}`}
        style={{cursor:'pointer'}}
        onClick = {()=> handleSave()}
        >Download Local Copy
      </span>

      <WebbDividerMedium />
      <WebbDividerSmall />
      <p className={`text-small text-color-tone m-0 ${done ? '': 'd-none'}`}>
        Note: We do not save information from this page.
      </p>
    </div>

    

  </>

  )
}