// wallets
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";


const listNetwork = [
  {code: '80001', name: 'Polygon Testnet'},
  {code: '137', name: 'Polygon'}
]


// lists --------------->


// code --------------->

export default function TokenDetailsModule(props) {

  const asset = {user: '******'}
  const {id} = useParams();
  console.log (props)

  const [loading, setLoading] = useState(true);  
  const [form, setForm] = useState(false);

  const [item, setItem] = useState({})

  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
      
        setItem(props.data)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[props.data]);


  if (loading) return <WebbLoader/>

  if (!props.data) return <></>

  return (
  <>
    
    {/* info */}
    
    {/* media */}
    <WebbDividerSmall />
    <div className='container rounded-wd p-2' style={{backgroundColor:'#1F1B24'}}>
      <div className='media-cube rounded-md' style={{backgroundColor:'#1F1B24'}}>
        <img src={item && item.media.link || '******'} alt={'...'}></img>
      </div>
    </div>
    
    <WebbDividerSmall />
    <div className='container rounded-wd p-3' style={{backgroundColor:'#1F1B24', color: '#F5F5F5'}}>
      <h1 className="text-lead">{item && item.ticket.name || '******'}</h1>
      <p className="text-normal text-sm m-0">from: {item && item.issuer.name || '******'}</p>
    </div>


    <WebbDividerSmall />
    <div className='container rounded-wd p-3' style={{backgroundColor:'#1F1B24', color: '#F5F5F5'}}>

      <p className='text-small m-0' style={{color: '#9E9E9E'}}>AMOUNT</p>
      <h1 className="m-0" style={{fontSize:'1.5rem'}}>
        <span>{''}</span>
        <span className='ms-1 text-normal text-uppercase'>{''}</span>
      </h1>
    </div>


  </>

  )
}