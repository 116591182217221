// wallets
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ethers } from 'ethers'

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";

import { MetadataCreate } from "../../services/srvc-store-realm";
import { TokensMint } from "../../services/srvc-tokens-realm";

const listNetwork = [
  { code: '80001', name: 'Polygon Testnet' },
  { code: '137', name: 'Polygon' },
  { code: '44787', name: 'Celo Testnet' },
  { code: '42220', name: 'Celo' }
]


// lists --------------->


// code --------------->

export default function TransfersMintModule(props) {

  const asset = {user: '******'}
  const {id} = useParams();

  const [loading, setLoading] = useState(true);  
  const [form, setForm] = useState(false);


  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  const [mintstatus, setMintStatus] = useState(false);

  const [transfer, setTransfer] = useState(); // transaction info
  const [wallet, setWallet] = useState(false);
  const [metamask, setMetamask] = useState(false);

  const [account, setAccount] = useState();
  const [accountstatus, setAccountStatus] = useState(false);
  const [balance, setBalance] = useState();
  const [balancestatus, setBalanceStatus] = useState(false);
  const [network, setNetwork] = useState();
  const [networkstatus, setNetworkStatus] = useState(false);

  const [item, setItem] = useState({})
  const [data, setData] = useState({
    name: '',
    mail: '',
    account: ''

  })

  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
        setItem(props.data);
        setData({...data, name: props.data.user.name, mail: props.data.user.mail})    
        setLoading(false);
      }
      fetchData()
    } else {}
  },[props.data]);

  // validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' 
      && (data.mail!=='' && data.mail.includes('@') && data.mail.includes('.'))
      && ((wallet && data.account.substring(0,2) ==="0x" && data.account.length === 42) || (account && account.substring(0,2) ==="0x" && account.length === 42))
      )  //&& fileBanner && fileBanner!==''
    { setForm(true); }

  },[data]);

  // metamask info
  useEffect(() => {
    const fetchData = async() => {
      if (window.ethereum){
        if (window.ethereum.isMetaMask) {
          setMetamask(true);
          console.log ('metamask wins')
        }
      } else {
        setMetamask(false);
        console.log ('metamask xxxx')
      }
    }
  fetchData();
  },[]);

  useEffect(() => {

    const fetchData = async() => {
      if (!metamask) {
        // Nothing to do here... no ethereum provider found
        return;
      }
      const chainWasChanged = (chainid) => {
        setNetwork(parseInt(chainid));
        console.log('chainWasChanged');
      }
      const getAndSetNetwork = async () => {
        const chainid = await window.ethereum.request({ method: 'eth_chainId' });
        setNetwork(parseInt(chainid));
        console.log('getAndSetNetwork');
      }
      const clearNetwork = () => { setNetwork(null); console.log('clearNetwork');
      };
      window.ethereum.on('chainChanged', chainWasChanged);
      window.ethereum.on('connect', getAndSetNetwork);
      window.ethereum.on('disconnect', clearNetwork);
      window.ethereum.request({ method: 'eth_chainId' }).then(chainid => {
        setNetwork(parseInt(chainid));
        console.log('chain', chainid);
        // No need to set account here, it will be set by the event listener
      }, error => {
        // Handle any UI for errors here, e.g. network error, rejected request, etc.
        // Set state as needed 
      })
      return () => {
        // Return function of a non-async useEffect will clean up on component leaving screen, or from re-reneder to due dependency change
        window.ethereum.removeListener('chainChanged', chainWasChanged);
        window.ethereum.removeListener('connect', getAndSetNetwork);
        window.ethereum.removeListener('disconnect', clearNetwork);
      }
    }
  fetchData();
  }, [metamask]);

  useEffect(() => {
    const fetchData = async() => {
      if (metamask){
        
        const getAndSetAccount = async () => {
          const changedAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          setAccount(changedAccounts[0]);
        }
        const accountWasChanged = (accounts) => { setAccount(accounts[0]) };
        const clearAccount = () => { setAccount(null); };

        window.ethereum.on('accountsChanged', accountWasChanged);
        window.ethereum.on('connect', getAndSetAccount);
        window.ethereum.on('disconnect', clearAccount);

        return () => {
          // Return function of a non-async useEffect will clean up on component leaving screen, or from re-reneder to due dependency change
          window.ethereum.removeListener('accountsChanged', accountWasChanged);
          window.ethereum.removeListener('connect', getAndSetAccount);
          window.ethereum.removeListener('disconnect', clearAccount);
        }

      } else {}
    }
  fetchData();
  },[metamask]);


  useEffect(() => {
    const fetchData = async() => {

      if (network && network == '80001') setNetworkStatus(true) 
      else setNetworkStatus(false)

      if (account && account !== '') setAccountStatus(true) 
      else setAccountStatus(false)

      if (parseFloat(balance && balance) > parseFloat(transfer && transfer.amnt.nmbr)) setBalanceStatus(true) 
      else setBalanceStatus(false)

      setForm(false)
      if (account && network == '80001' && (parseFloat(balance && balance) > parseFloat(transfer && transfer.amnt.nmbr)) )
      setForm(true)

    }
    fetchData();
  }, [transfer, account, network, balance]);


  const handleAccountMetamask = async() => {

    window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
      console.log('accounts', accounts);
      setAccount(accounts[0]);
      // No need to set account here, it will be set by the event listener
    }, error => {
      console.log(error)
    })

  }

  const handleTokenMintMetamask = async() => {
    
    setSubmit(true)

    var userauth
    try {
      const from = account;
      const msg = `Tokenize and Mint Asset Token ID ${item.asset.number}`;
      userauth = await window.ethereum.request({
        method: 'personal_sign',
        params: [msg, from, ''],
      });
      console.log(userauth)
      // personalSignResult.innerHTML = sign;
      // personalSignVerify.disabled = false;
    } catch (err) {
      console.error(err);
      // personalSign.innerHTML = `Error: ${err.message}`;
    }

    if (userauth) {

      // metadata
      var datx = item
      const metalink = await MetadataCreate({data: {file: datx}, user: '******'})

      console.log(metalink)

      datx = {
        issuer: process.env.REACT_APP_MINT_BASE_ACNT,
        user: metamask ? account : data.account,
        tokenid: item.asset.number,
        metadata: metalink.data.link,
        collection: item.ticket.item,
        chain: item.asset.chain,
        tradable: true, mendable: true, burnable: true,
        owner: item.issuer.item,
      }
      console.log (datx)
      const result = await TokensMint({data: datx, user: '******'})
      console.log(result)

      if (result) {
        setDone(true)
        setMintStatus(true)
      }

    }



    // const result = await TokensMint({data: datx, user: ''})
    // console.log (result)

  }  

  const handleTokenMint = async() => {
    
    const datx = {
      issuer: "0xbC1c628bfDc9156e11b2525fc19D64A9AFE8895c",
      user: metamask ? account : data.account,
      collection: '123456789X',
      tradable: false,
      mendable: true,
      burnable: true,
      chain: '80001',
      owner: '3eb5e162dcc14f95be86ec05a1e500774'
    }

    const result = await TokensMint({data: datx, user: ''})
    console.log (result)

  }  


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }



  if (loading) return <WebbLoader/>
  if (!loading && !item) return <>
    <div 
      className='container rounded-wd p-3 text-center' 
      style={{backgroundColor:'#1F1B24', color: '#F5F5F5'}}
    >
      <p className="text-color-wite">Invalid Token</p>

    </div>
  
  </>

  return (
  <>
    
    {/* info */}
    
    {/* media */}
    <WebbDividerSmall />
    <div className='container rounded-wd p-3' 
      style={{backgroundColor:'#1F1B24', color: '#F5F5F5'}}>

        <div className="mb-3">
          User Info
        </div>

        <div className="mb-3">  
          <label className="form-label" style={{color: '#9E9E9E'}}>Name </label>
          <input type="text" 
            className="form-control height-md border-none"
            style={{fontSize:'0.9rem', backgroundColor:'#121212', color: '#F5F5F5'}} 
            value={data.name}
            onChange={({ target }) => {handleChange("name", target.value); }}
            disabled
            placeholder="Enter Name">
          </input>
        </div>      

        <div className="mb-3">  
          <label className="form-label" style={{color: '#9E9E9E'}}>Email Address </label>
          <input type="text" 
            className="form-control height-md border-none"
            style={{fontSize:'0.9rem', backgroundColor:'#121212', color: '#F5F5F5'}} 
            value={data.mail}
            onChange={({ target }) => {handleChange("mail", target.value); }}
            disabled
            placeholder="user@mail.com">
          </input>
        </div>  

    </div>
    
    <WebbDividerSmall />
    <div className='container rounded-wd p-3' style={{backgroundColor:'#1F1B24', color: '#F5F5F5'}}>

      <div className="mb-3">
        Minting Info
      </div>

      <div className="mb-3">
        <p className="mb-1" style={{color: '#9E9E9E'}}>Token ID</p>
        <p className="text-lead">{item && item.asset.number || '******'}</p>
      </div>
      <WebbDividerSmall />

      <div className={metamask ? '': 'd-none'}>

        <div className="mb-3">
          <label className="form-label" style={{color: '#9E9E9E'}}>
            <span><i className={`bx bxs-circle text-small text-color-${networkstatus ? 'success' : 'danger'}`}></i></span>
            <span className="ms-1">Network</span>
          </label>
          <p className="text-normal">{item && listNetwork.find(x => x.code == item.asset.chain).name || '******'}</p>
          <p className="text-normal d-none">{network && listNetwork.find(x => x.code == network).name || '******'}</p>
          <p className="text-small mt-1">{network && network == item.asset.chain ? ' ' : 'Please switch to compatible network'}</p>
        </div>
        
        <WebbDividerSmall />
        <div className="mb-3">
          <label className="form-label" style={{color: '#9E9E9E'}}>
            <span><i className={`bx bxs-circle text-small text-color-${account ? 'success' : 'danger'}`}></i></span>
            <span className="ms-1">Wallet Address</span>
          </label>
          <p className="text-normal d-none">{account || '******'}</p>
          <input type="text" 
            className="form-control height-md border-none"
            style={{fontSize:'0.9rem', backgroundColor:'#121212', color: '#F5F5F5'}} 
            value={account}
            onChange={({ target }) => {handleChange("account", target.value); }}
            placeholder="0x123456...">
          </input>
        </div>
      </div>

      <div className={metamask ? 'd-none': ''}>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" checked={wallet}
            onChange={() => setWallet(!wallet)}
          >
          </input>
          <label className="form-check-label">
            I have Ethereum Wallet
          </label>
        </div>

        <WebbDividerSmall />
        <div className={wallet ? 'd-none' : ''} style={{color: '#9E9E9E'}}>
          Dont have Ethereum Wallet, <a href="https://mint.tokenize.ee/wallet" target={'_blank'}>Create Here</a>
        </div>

        <div className={wallet ? '' : 'd-none'}>  
          <label className="form-label" style={{color: '#9E9E9E'}}>Wallet Address </label>
          <input type="text" 
            className="form-control height-md border-none"
            style={{fontSize:'0.9rem', backgroundColor:'#121212', color: '#F5F5F5'}} 
            value={data.account}
            onChange={({ target }) => {handleChange("account", target.value); }}
            placeholder="0x123456...">
          </input>
        </div> 

      </div>



      <div className="">
        <WebbDividerMedium />
        {/* action */}
        <button type="button" 
          className={`btn btn-primary text-small w-100 border-none ${metamask && !account ? '' : 'd-none'}`}
          style={{backgroundColor:'orange'}}
          onClick={() => handleAccountMetamask()}
        >
          Connect Account
        </button>

        <button type="button" 
          className={`btn btn-primary text-small w-100 border-none ${metamask && accountstatus ? '' : 'd-none'}`}
          style={{backgroundColor:'orange'}}
          onClick={() => handleTokenMintMetamask()}
          disabled= {!networkstatus || !accountstatus || submit }
        >
          Mint Token
        </button>

        <button type="button" 
          className={`btn btn-primary text-small w-100 border-none ${metamask ? 'd-none' : ''}`}
          disabled = {!form}
          onClick={() => handleTokenMint()}
        >
          Mint Now
        </button>
      </div>


      {/* status */}
      <div className={submit ? 'container rounded-wd p-3' : 'd-none'}
        style={{backgroundColor:'#1F1B24', color: '#F5F5F5'}}
      >
        <p className="m-0">Please Wait, Mint in Progress</p>
        <p className={done && mintstatus ? 'm-0': 'd-none'}>Success</p>
        <p className={!done && !mintstatus ? 'd-none': 'd-none'}>Failed, Please Try Again</p>
      </div>

    </div>

    <WebbDividerMedium />
    

  </>

  )
}