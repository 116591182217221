// wallets
import { useState } from "react";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";

import { CreateAlgorandWallet } from "../../services/srvc-wallet-rand";

const icon = require('../../media/rand-icon-full.png')

// lists --------------->


// lists --------------->


// code --------------->

export default function WalletsCreateAlgorandModule() {

  const [loading, setLoading] = useState(false);  
  
  // const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    address: '******',
    mnemonic: '******',
    secret: '******'
  })


  const handleWallet = async() => {
    setLoading(true);

    const wallet = await CreateAlgorandWallet();
    // console.log (wallet);
    setData({
      address: wallet.data.address,
      mnemonic: wallet.data.mnemonic.toString(),
      secret: wallet.data.secret
    })
    setDone(true);
    setLoading(false);
  }

  function handleSave() {

    const content = '' + 
      '\n--------------------------------------------------\n' +
      '\nHTTPS://TOKENIZE.SO' + 
      '\n\n--------------------------------------------------\n' +
      '\n\nALGORAND BLOCKCHAIN ACCOUNT ADDRESS \n> ' + data.address + 
      // '\n\nView Account on Mainnet\n> ' + `https://algoexplorer.io/address/${data.address}` +
      // '\n\nView Account on Testnet\n> ' + `https://testnet.algoexplorer.io/address/${data.address}` +
      '\n\n--------------------------------------------------\n' +
      '\n\nRECOVERY PHASE (MNEMONIC)\n' + '*** DO NOT SHARE THIS WITH ANYONE ***\n> ' + data.mnemonic +
      '\n\n--------------------------------------------------\n' +
      '\nIf you lose your recovery phrase or private key, you will not be able to access your wallet or its funds. It is always a good idea to back it up either by writing down in private diary or printing it out.' + 
      '\n\n--------------------------------------------------\n'

    const blob = new Blob([content], {type: 'text/plain'});

    if(window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `tokenize-wallet-${Date.now().toString()}`);
    }
    else{
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = `tokenize-algorand-${Date.now().toString()}`;
        document.body.appendChild(elem);
        elem.click();  
        document.body.removeChild(elem);
    }
}

  if (loading) return <WebbLoader/>

  return (
  <>
    
    {/* media */}
    <div className="p-3 back-color-wite rounded-wd">
      <img src={icon} height='33rem'></img>
      <WebbDividerMedium />
      <h3 className="text-lead text-color-main m-0">Create Account</h3>
      <WebbDividerSmall />
      <p className="m-0 mb-2">
        You can Create a New Alogrand Account here. It will work on both Testnet and Mainnet chains.
        Once created, you can import this into wallets like Pera (mobile and web).
      </p>
      <p className="m-0 d-none">
        Alternative Option: You can visit <a href="https://metamask.io">metamask.io</a> to setup and create a wallet.
      </p>

      <WebbDividerMedium />
      <button
        onClick={() => handleWallet()}
        className={`btn btn-dark height-md hirich rounded-wd border-none`}
        style={{width:'100%'}}>
        Create Account
      </button>
    </div>
    
    <WebbDividerMedium />


    <WebbDividerMedium />
    <div className={`p-3 back-color-wite rounded-wd ${done ? '': 'd-none'}`}>
      <p className="text-bold m-0" style={{textTransform:'uppercase'}}>Wallet Address</p>
      <p className="text-color-main m-0 font-monospace text-break">{data.address}</p>
      
      <div className="mb-1"></div>
      <div className="btn back-color-lite border rounded-wd text-mini"
        onClick={() => {navigator.clipboard.writeText(data.address); alert('Copied Address')}}
      >COPY</div>

      <p className="text-mini text-color-tone m-0 mt-1">You can share this address with other to claim and collect digital assets</p>

      <WebbDividerMedium />
      <p className="text-bold m-0" style={{textTransform:'uppercase'}}>Recovery Phrase</p>
      <p className="text-color-main m-0 font-monospace">{data.mnemonic}</p>
      <div className="mb-1"></div>
      <div className="btn back-color-lite border rounded-wd text-mini"
        onClick={() => {navigator.clipboard.writeText(data.mnemonic); alert('Copied Mnemonic')}}
      >COPY</div>

      <p className="text-mini text-color-tone m-0 mt-1">Do not share this with anyone, and write it down somewhere and keep it safe. This can be used to recover your account.</p>
      {/* <WebbDividerMedium />
      <p className="text-bold m-0" style={{textTransform:'uppercase'}}>Private Key</p>
      <p className="text-color-main m-0 text-sm">{data.secret}</p>

      <p className="text-mini text-color-tone m-0">Do not share this with anyone, and Write it down somewhere and keep it safe. This can be used to recover your account.</p>
     */}
      <WebbDividerMedium />
      <WebbDividerMedium />
      
      
      <span 
        className={`back-color-dark text-color-wite p-2 px-3 rounded-md hirich ${done ? '': 'd-none'}`}
        style={{cursor:'pointer'}}
        onClick = {()=> handleSave()}
        >Download Local Copy
      </span>

      <WebbDividerMedium />
      <WebbDividerSmall />
      <p className={`text-small text-color-tone m-0 ${done ? '': 'd-none'}`}>
        Note: We do not save information from this page in our database or server. the information will be lost once the page is closed or refresh.
      </p>
    </div>

    

  </>

  )
}