// main
import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import { AssetDetails } from "../services/srvc-assets-realm";

import TokenDetailsModule from "../content/transfers/tokens-details";
import TransfersMintModule from "../content/transfers/transfers-mint";

export default function TransfersMint () {
  
  const meta = {
    name: 'Tokenize and Mint',
    media: 'https://img.freepik.com/premium-photo/3d-wireframe-head-man-wear-vr-virtual-reality-headset-with-city-background-3d-illustration-rendering_37129-2979.jpg?w=600'
  }

  const asset = {user: '******'}
  const {id} = useParams();

  const [loading, setLoading] = useState(false);  

  const [item, setItem] = useState()

  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = await AssetDetails({
          data: {item: id},
          user: '******'
        })
        console.log (result)
        setItem(result.data)
        
        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {meta.name}

      header = {{ size: 'medium', data: 
      <>
        <div className="">
          
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <>
        <div className="">
         
        </div>
      </>
      }}

      content = {{ size: 'wide', data: 
      <>
        <WebbDividerSmall />
        <div className="text-color-wite">
          <p className="text-lead text-center m-0">
            <span className="text-bold">TOKENIZE & MINT</span>
            <span className="ms-1"></span>
          </p>
        </div>
        
        <WebbDividerSmall />
        <div className={!loading && !item ? '': 'd-none'} style={{}}>
        <WebbDividerSmall />
          <div 
            className='container rounded-wd p-3 text-center' 
            style={{backgroundColor:'#1F1B24', color: '#F5F5F5'}}>
              <WebbDividerMedium />
            <i className="caption-md bx bx-info-circle"></i>
            <div className="mb-1"></div>
            <p className="text-normal m-0">{'Invalid Token or Link'}</p>
            <WebbDividerMedium />
          </div>
        </div>
        <div className={!loading && item ? '': 'd-none'} style={{}}>

          <div className=''>
            <div className='row'>

              <div className='col-md-6'>
                <TokenDetailsModule data={item} />

              </div>

              <div className='col-md-6'>
                <TransfersMintModule data={item} />

              </div>

            </div>
          </div>
          

          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
        </div>

      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <>
        <div className="">
        
        </div>
      </>
      }}
    
    
    ></ContentFormat>


  </>
  )
}