// init
const ethers = require('ethers');


// Function Calls


export const CreateEthereumWallet= async (item) => {

  
  var wallet;
  try {
    wallet = ethers.Wallet.createRandom();
    console.log (wallet)
    return {data: {address: wallet.address, mnemonic: wallet.mnemonic.phrase, secret: wallet.privateKey}}

  } catch (error) {
    console.log (error);
    return {data: false}
  }
  
}